@import "style/colors";

.button {
  align-self: center;
  padding: 1em 2em;
  background: $MintGreen;
  color: $WhiteText;
  text-decoration: none;
  font-weight: 500;
  border-color: $MintGreen;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 5px rgba(204, 204, 204, 0.658);
  display: inline-block;
  &.small {
    padding: 0.5em 1em;
  }
  &.big {
    width: 100%;
    font-size: 1.2em;
    border-radius: 5px;
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.9;
  }
  &.link {
    border: none;
    padding: 0;
    color: $MintGreen;
    background: none;
    box-shadow: none;
    &:hover {
      color: #8b8ae1;
    }
  }
  &:focus-visible {
    outline-color: $DarkerGreen;
  }
}

.button + .button {
  margin-left: 1em;
}
.green {
  background: $MintGreen;
}

.outlined {
  border: 2px solid $MintGreen;
  background: $WhiteText;
  color: $MintGreen;
  &:hover {
    background: $MintGreen;
    color: $WhiteText;
  }
}

.darkGreen {
  background: $DarkerGreen;
  &:hover {
    opacity: 0.8;
  }
}

.blue {
  background: $DarkBlue;
}
