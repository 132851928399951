@import "style/colors";

.section {
  min-height: 300px;
}
.white {
  background: $White;
}
.lightWhite {
  background: $LightWhite;
}
.lightBlue {
  background: $LightBlue;
}

.section,
.white,
.lightWhite,
.lightBlue {
  padding: 0 20px;

  @media screen and (max-width: 576px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 10px;
  }
}
