.container {
  width: 50%;
  min-height: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
