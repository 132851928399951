@import "style/colors";

.title {
  font-size: 4rem;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(80, 79, 186, 1) -37%,
    rgba(159, 79, 187, 1) 70%
  );

  filter: alpha(opacity=60);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    color: rgb(159, 79, 187);
    background: none;
  }
  }
