@import "../../../style/_colors";

.mobileMenu {
  transform: translateX(100%);
  background-color: $Blue;
  position: absolute;
  height: 100vh;
  width: 300px;
  top: 0px;
  right: 0;
  transition: transform 0.5s ease-out, opacity 0.3s ease-in;
  opacity: 0;

  ul {
    margin-top: 4rem;
  }
}

.mobileMenu.transl {
  position: fixed;
  z-index: 2;
  transform: translateX(0);
  opacity: 1;
  transition: transform opacity 0.5s ease-out, opacity 0.3s ease-in;
}
