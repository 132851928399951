.layout {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  justify-content: space-evenly;
  &.narrow {
    max-width: 600px;
  }
}

.top,
.topColumn,
.topCenter {
  flex-direction: column;
}

.right {
  flex-direction: row-reverse;
}

.left {
  flex-direction: row;
}

.right,
.left {
  gap: 4rem;
}

.topCenter {
  text-align: center;
}

.top,
.right,
.left,
.topCenter {
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}
