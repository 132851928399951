@import "style/colors";

.form {
  height: 90%;
  @media screen and (max-width: 767px) {
    fieldset {
      min-width: 100%;
    }
  }
}
