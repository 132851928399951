@import "./colors";
@import "./fonts";
@import "../style/reset";

html {
  font-size: 16px;
}

form {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

::selection {
  background: $MintGreen;
  color: $WhiteText; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $MintGreen; /* Gecko Browsers */
  color: $WhiteText;
}

body {
  margin: 0;
  font-family: "franklin-gothic-urw-cond", "Arial Narrow", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

li {
  display: block;
}
