@import "style/colors";

.quickLinksItem {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  a {
    text-decoration: none;
    color: $WhiteText;
    &:hover {
      color: $PurpleColor;
    }
    &.activeFooterLink {
      font-weight: 600;
    }
  }
}
