@import "style/colors";

.footer {
  background: url(../../assets/images/footer.jpg);
  background-size: contain;
  min-height: 800px;
  color: white;

  .ceSymbols {
    display: flex;
    width: 150px;
    flex-wrap: wrap;
    align-items: baseline;
    & > img {
      margin-right: 0.5rem;
    }
  }
  .refContainer {
    display: flex;
    align-items: center;
  }
  .adressContainer {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  }
}
