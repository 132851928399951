@import "style/colors";

.sectionWithBackground {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 0 20px;
  @media screen and (max-width: 576px) {
    padding: 0 10px;
  }
}
