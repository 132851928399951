@import "../../../style/colors";

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  .optional {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    color: $DarkGrey;
  }
  .inputFormat {
    color: $DarkGrey;
  }
}
