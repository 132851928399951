@import "style/colors";

.header {
  position: fixed;
  overflow: hidden; // Hide mobileMenu outside of view to prevent horizontal scrolling but remain animation on opacity
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 2;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
    position: absolute;
  }
  .background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #6b6bae, #91cfc8);
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    &.transp {
      opacity: 0;
      transition: opacity 0.3s ease;
      @media screen and (max-width: 990px) {
        opacity: 1;
      }
    }
    &.cover {
      position: fixed;
      opacity: 0.8;
      width: 100vw;
      height: 100vh;
      transition: opacity 0.5s ease;
    }
  }

  .layout {
    height: 66px;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $WhiteText;
    transition: height 0.5s ease;
    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
    & > * {
      z-index: 3;
    }
    .logoLink {
      z-index: 1;
    }
    &.active {
      height: 44px;
    }
  }
  .logoContainer {
    display: flex;
    // gap: 30px;
    align-items: center;
    min-width: 125px;
    img:nth-child(2) {
      margin-left: 15px;
    }
  }
}
