@import "../../../style/colors";

.description {
  color: $DarkGrey;
  text-align: left;
  margin-top: 1rem;
  display: block;
}

.red {
  color: red;
}
