@import "style/colors";
@import "../../style/sizes";

.callout {
  background: $WhiteText;
  width: 100%;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 2rem;
  border-radius: $RoundedBorders;
  &:hover {
    opacity: 0.8;
    transition: 0.3s opacity ease-in-out;
  }
  a {
    color: $PurpleColor;
    text-decoration: underline;
    &:before {
      background-image: url("../../assets/icons/link.png");
      background-size: 15px 15px;
      background-repeat: no-repeat;
      display: inline-block;
      width: 15px;
      height: 15px;
      padding-right: 1.5rem;
      content: "";
    }
  }
  img {
    width: 10px;
  }
}
