@import "../../../../style/_colors";

a {
  text-decoration: none;

  .mobileMenuItem {
    display: flex;
    padding: 0 50px;
    line-height: 2;
    margin: 10px 0;
    color: $WhiteText;
    text-decoration: none;
    border-bottom: 1px solid $PurpleColor;
    font-size: 1.3rem;
    font-weight: 400;
    &:hover {
      background-color: $PurpleColor;
    }
  }
}
