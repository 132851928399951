@import "../../../style/_colors";
@import "../../../style/_sizes";

.menuItem {
  margin-right: 1rem;
  z-index: 1;
}

.listItem {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: $WhiteText;
  border-bottom: 2px solid transparent;
  &:hover {
    opacity: 0.8;
    border-bottom-color: rgba(255, 255, 255, 0.801);
  }
  &.activeLink {
    border-bottom-color: white;
  }
}

.fill {
  background-color: #fff;
  color: $PurpleColor;
  &:hover {
    border-bottom: none;
  }
}

.outlined {
  color: $WhiteText;
  border: 2px solid white;
}

.fill,
.outlined {
  padding: 0.5rem 1rem;
  height: 2rem;
  border-radius: 1.5px;
  font-size: 0.75rem;
  text-transform: uppercase;
}
