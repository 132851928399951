$RedColor: red;
$WhiteText: #fff;
$White: #fff;
$LightBlue: #f8f8fe;
$LightWhite: #f6f6f6;
$LightGrey: #ebebeb;
$DarkGrey: #9b9b9b;
$DarkestGrey: #6b6b6b;
$PurpleColor: #8b8ae1;
$DarkPurple: #bfbffa;
$GreenColor: #91cfc8;
$DarkerGreen: rgb(70, 191, 181);
$MintGreen: #91cfc8;
$Blue: #6b6bae;
$DarkBlue: #6e83dd;
