$skeletonBaseColor: #eee;
$skeletonHighlightColor: #f5f5f5;

@keyframes skeletonKeyframes {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.skeleton {
  background-color: $skeletonBaseColor;
  background-image: linear-gradient(
    90deg,
    $skeletonBaseColor,
    $skeletonHighlightColor,
    $skeletonBaseColor
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeletonKeyframes 1.2s ease-in-out infinite;
}
