@import "../../../style/colors";

.radioWrapper {
  display: flex;
  text-align: left;
  padding: 1rem 0;
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    cursor: pointer;
    display: inline-block;
    color: #6b6bae;
    border-left: 4px solid #6b6bae;
    padding: 1em 2em;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
    background-color: #fdffff;
  }
  input[type="radio"]:hover + label {
    background: linear-gradient(90deg, #504fba -37%, #9f4fbb 70%);
    opacity: 0.5;
    color: $WhiteText;
    transition: opacity 0.5s ease;
  }
  input[type="radio"]:focus + label {
    box-shadow: 0 0px 8px $PurpleColor;
  }
  input[type="radio"]:checked + label {
    background: linear-gradient(90deg, #504fba -37%, #9f4fbb 70%);
    color: $WhiteText;
    opacity: 1;
    transition: opacity 0.7s ease;
  }
}
