@import "style/colors";

.input,
.selectInput {
  padding: 0.5em 1em;
  width: 100%;
  border: none;
  color: #6b6bae;
  height: 54px;
  border-radius: 8px;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  background-color: #fdffff;
  &:focus {
    outline-color: $MintGreen;
  }
  &:disabled {
    background-color: rgb(218, 218, 218);
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.05);
    cursor: not-allowed;
  }
}
textarea {
  &.input {
    min-height: 112px;
    height: auto;
  }
}
.radioInput {
  display: flex;
  justify-items: center;
  align-items: center;
  input {
    box-shadow: none;
    width: 27px;
    height: 27px;
    margin-right: 1em;
    &:focus {
      outline-color: $MintGreen;
    }
  }
}

.label {
  color: $DarkestGrey;
  display: inline-block;
  font-weight: 500;
  padding: 0.5em 0;
  small {
    color: $DarkGrey;
    font-weight: 300;
    display: block;
  }
}

.inputGroup {
  margin: 0 0 1em 0;
  width: 100%;
}
