@import "style/colors";

:root {
  --input-border: #8b8a8b;
  --input-focus-h: 245;
  --input-focus-s: 100%;
  --input-focus-l: 42%;
}

.loginForm {
  @media screen and (max-width: 768px) {
    min-width: 100%;
    margin: 0;
  }
  .inputContainer {
    max-width: 400px;
    // margin: 0 auto;
    border: none;
    @media screen and (max-width: 768px) {
      min-width: 100%;
      margin: 0;
    }
  }

  .submitting {
    margin: 0 auto;
    padding: 3em 0;
    text-align: center;
    min-width: 300px;
  }

  button {
    background-color: $MintGreen;
    color: $WhiteText;
    border-color: $MintGreen;
    text-transform: uppercase;
    padding: 15px 30px 13px;
    font-weight: 500;
    border-radius: 3px;
  }
  a,
  a:visited,
  .subTitle {
    color: $MintGreen;
  }
}
