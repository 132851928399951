.consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(126, 209, 200);
  text-align: center;
  padding: 0.5em;
  color: white;
}
