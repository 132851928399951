@import "style/colors";

.listItem {
  display: list-item;
  padding-bottom: 2rem;
  &::marker {
    color: $DarkGrey;
    font-size: 1.3rem;
  }
  a {
    color: $MintGreen;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 400;
    text-decoration: none;
  }
}

.listItem {
  &:hover {
    text-decoration: underline;
  }
}
