@import "style/colors";

.paragraph {
  color: $PurpleColor;
  font-size: 1.25em;
  line-height: 1.5em;
  font-weight: 200;
  margin-bottom: 1rem;
  white-space: pre-line;
}

.white {
  color: $WhiteText;
}

.purple {
  color: $PurpleColor;
}

.grey {
  color: $DarkGrey;
}
