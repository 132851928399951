@import "style/colors";
@import "../../style/text";

.spotlightTitle {
  font-size: 36px;
  font-weight: 400;
  color: $WhiteText;
  margin-bottom: 1rem;
  font-style: italic;
  &:hover {
    text-decoration: underline #fff;
    cursor: pointer;
  }
}
