@import "../../../style/colors";

.selectWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  height: 100%;
  .optional {
    margin-left: 0.5rem;
    font-weight: 100;
    font-size: 0.8rem;
    color: $DarkGrey;
  }
}
