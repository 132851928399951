.root {
  position: absolute;
  right: 5%;
  z-index: 20;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  & > div {
    background: #fff;
    height: 2px;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }
  &.isOpen {
    & > div:nth-child(1) {
      transform: translateX(15px) rotate(45deg) translateY(15px);
      transition: transform 0.5s ease-in-out;
    }
    & > div:nth-child(2) {
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
    }
    & > div:nth-child(3) {
      transform: translateX(15px) rotate(-45deg) translateY(-15px);
      transition: transform 0.5s ease-in-out;
    }
  }
}
