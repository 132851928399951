.fileInput {
  display: none;
}

.dropMessage {
  text-align: center;
  color: #aaa;
}

.dropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8fe;
  height: 100px;
  border: 1px dashed #ccc;
}

.imageThumbnail {
  padding: 3px;
  background: white;
  border: 1px solid #ccc;
  max-height: 100%;
  max-width: 100%;
}

.imageThumbnailContainer {
  max-width: 200px;
  height: 110px;
}

.fileDisplayContainer {
  margin: 1em 0;
}

.fileStatusbar {
  width: 100%;
  vertical-align: top;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.fileType {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.fileName {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.fileError {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.fileErrorMessage {
  color: red;
}

.fileSize {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.fileRemove {
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
}
