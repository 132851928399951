.imageContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .image {
    max-height: 450px;
    object-fit: contain;
    cursor: pointer;
    vertical-align: top;
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.5s ease-out;
    width: 100%;
    height: 10%;
    &:hover {
      -moz-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
}

.imageContainer {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.imageContainer.image {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
