@import "style/colors";

.list {
  color: $WhiteText;
  .listItem {
    padding-bottom: 1rem;
    font-size: 1.15em;
    line-height: 1.4em;
    display: flex;
    &::before {
      content: url("../../assets/icons/checkmark.png");
      padding-right: 1rem;
    }
  }
}
