@import "style/colors";
@import "../../style/text";
@import "../../style/_sizes";

.spotlightContainer {
  width: 100%;
  text-align: left;
}
.buttonStyles {
  align-self: center;
  background-color: $MintGreen;
  padding: 15px 30px 13px;
  color: $WhiteText;
  text-decoration: none;
  font-weight: 500;
  border-color: $MintGreen;
  border-radius: 3px;
  -webkit-box-shadow: 0px 7px 5px -4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 7px 5px -4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 7px 5px -4px rgba(0, 0, 0, 0.3);
  &:hover {
    opacity: 0.9;
  }
}
