@import "style/colors";

.heading {
  font-size: 2rem;
  color: $PurpleColor;
  font-weight: 400;
  margin-bottom: 1rem;
}

.white {
  color: $WhiteText;
}

.darkPurple {
  color: $DarkPurple;
}
