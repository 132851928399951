.fieldset {
  border: none;
  min-width: 500px;
  @media screen and (max-width: 767px) {
    min-width: 100vw;
  }
  &.lg {
    min-width: 700px;
    @media screen and (max-width: 767px) {
      min-width: 100vw;

    }
  }
  &.md {
    min-width: 500px;
    @media screen and (max-width: 767px) {
      min-width: 100vw;
    }
  }
}
