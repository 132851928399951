.inputWrapper,
.inputWrapperNoMargin {
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1;
  }
}

.inputWrapper {
  margin: 0 0 1em 0;
}
