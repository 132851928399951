.prostateForm {
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    width: 100vw;
  }
  .headingContainer {
    margin: 0 10px;
  }
}

.loadingContainer {
  text-align: center;
  margin: 2rem 0;
}
