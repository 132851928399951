@import "style/colors";
@import "../../style/text";

.heroTitle {
  font-size: $FontLarge;
  color: $WhiteText;
  font-family: "franklin-gothic-urw-cond", "Arial Narrow", Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}
