.image {
  max-height: 450px;
  min-height: 300px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 1rem;
}

.small {
  height: 300px;
  width: 400px;
}
