@import "style/colors";
.tabs {
  nav {
    margin: 2px auto 2em auto;
    border-bottom: 1px solid $DarkerGreen;
    @media screen and (max-width: 768px) {
      border-bottom: 0;
    }
  }

  .tabNav {
    margin: 0px;
    padding: 0px;
    padding-left: 48px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-left: 0;
      margin: 0 10px;
    }
  }

  .tabNavItem {
    display: block;
    background-color: #fff;
    margin-right: 46px;
    cursor: pointer;
    transition: all 250ms ease;
    margin-bottom: -1px;
    z-index: 1;
    position: relative;
    border: 1px solid white;
    border-bottom-color: $DarkerGreen;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      border-color: $DarkerGreen;
      opacity: 0.7;
    }

    &:hover {
      background-color: #f4f7f9;
      color: #444;
      @media screen and (max-width: 768px) {
        background-color: #fff;
        }
    }

    &.active {
      border-color: $DarkerGreen;
      border-bottom-color: white;
      @media screen and (max-width: 768px) {
      background-color: #f4f7f9;
      border-bottom-color: $DarkerGreen;
      opacity: 1;
      }
    }
  }

  .tabNavItemLink {
    padding: 1em 2em;
    font-size: 1.5em;
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
}

.tabContent {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}
