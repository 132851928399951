.spotlight {
  margin: 2rem 0;
  li {
    overflow: hidden;
    height: 160px;
    padding: 10px;
    line-height: 30px;
    list-style: none;
    text-align: left;
  }
}
